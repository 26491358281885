<script setup lang="ts">
import {
  MDBNavbar,
  MDBNavbarToggler,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBCollapse,
  MDBDropdown, MDBDropdownToggle, MDBDropdownItem, MDBDropdownMenu,
  MDBIcon, MDBRow,
  MDBBadge
} from 'mdb-vue-ui-kit';

import {ref, computed} from 'vue';
import {user_store, version} from "../user_store";
import Vidle from 'v-idle'
import {useRoute, useRouter} from "vue-router";

const router = useRouter()
const route = useRoute()
const collapse2 = ref(false);
const dropdown1 = ref(false);
const dropdown2 = ref(false);
const dropdown3 = ref(false);

const user_name = computed(() => {
  const fn = user_store.getState().first_name
  const ln = user_store.getState().last_name
  return fn + ' ' + ln
})

const has_dienstmodell = computed<boolean>(() => (user_store.getState().institution.data ? Object.keys(user_store.getState().institution.data).includes('Dienstmodell') : false))

const isPlaner = computed(() => user_store.getState().groups.includes('Planer'))
const isVerwaltung = computed(() => user_store.getState().groups.includes('Verwaltung'))
const isReader = computed(() => user_store.getState().groups.includes('Reader'))

// Physician groups der Institution noch einlesen
const isPhysician = computed(() => {
  return user_store.getState().physician_groups_of_my_institution?.some(item => user_store.getState().groups.includes(item))
})


</script>


<template>
  <MDBNavbar light bg="light" expand="lg" container>
    <a href="/">
      <MDBNavbarBrand class="me-2">
        <img
            src="/worklifebalance.png"
            height="38"
            alt="worklife balance"
            loading="lazy"
        />
        <span class="me-3">

        DienZt
          <MDBBadge color="primary" class="translate-bottom p-1"
               pill
                notification>{{ version }}
          </MDBBadge> </span>
      </MDBNavbarBrand>

    </a>
    <MDBNavbarToggler
        target="#navbarNav"
        @click="collapse2 = !collapse2"
    ></MDBNavbarToggler>
    <MDBCollapse id="navbarNav" v-model="collapse2">
      <MDBNavbarNav class="mb-2 mb-lg-0 d-flex flex-row">
        <MDBNavbarItem to="/"><i class="fas fa-house me-2"></i></MDBNavbarItem>
        <MDBNavbarItem to="/wishlist" v-if="isPhysician" class="me-2">Wunschliste</MDBNavbarItem>
        <MDBNavbarItem to="/dienstplan" v-if="isPhysician || isPlaner || isReader || isVerwaltung" class="me-2">
          Dienstplan
        </MDBNavbarItem>
        <MDBNavbarItem to="/dienstbroker" v-if="isPhysician" class="me-2">Markt</MDBNavbarItem>
        <MDBNavbarItem to="/abrechnung" v-if="isPhysician && has_dienstmodell" class="me-2">Abrechnung</MDBNavbarItem>

        <MDBDropdown v-model="dropdown1" v-if="isPlaner" class="me-2">
          <MDBDropdownToggle
              tag="a"
              class="nav-link"
              @click="dropdown1 = !dropdown1"
          >
            Planung
          </MDBDropdownToggle>
          <MDBDropdownMenu>
            <MDBDropdownItem to="/berechtigungen">Dienstgruppen</MDBDropdownItem>
            <MDBDropdownItem to="/inaugurate">Neu anlegen</MDBDropdownItem>
            <MDBDropdownItem to="/changeholidays">Feiertage bearbeiten</MDBDropdownItem>
            <MDBDropdownItem to="/automation-settings">Automation</MDBDropdownItem>
            <MDBDropdownItem to="/schedulize">Planen</MDBDropdownItem>
            <MDBDropdownItem to="/statistics">Statistik</MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>

        <MDBDropdown v-model="dropdown2" v-if="isVerwaltung" class="me-2">
          <MDBDropdownToggle
              tag="a"
              class="nav-link"
              @click="dropdown2 = !dropdown2"
          >
            Verwaltung
          </MDBDropdownToggle>
          <MDBDropdownMenu>
            <MDBDropdownItem to="/verwaltungsprofil">Profil</MDBDropdownItem>
            <MDBDropdownItem to="/mitarbeiterzuordnung">Menschen zu Mitarbeitern</MDBDropdownItem>
            <MDBDropdownItem to="/berechtigungen">Dienstgruppen</MDBDropdownItem>
            <MDBDropdownItem to="/dienstmodell">Dienstmodell</MDBDropdownItem>
            <MDBDropdownItem to="/statistics">Statistik</MDBDropdownItem>

          </MDBDropdownMenu>
        </MDBDropdown>
      </MDBNavbarNav>
    </MDBCollapse>

    <MDBNavbarNav right class="mb-2 mb-lg-0 d-flex flex-row">
      <MDBNavbarItem class="me-3 me-lg-0 dropdown">
        <MDBDropdown v-model="dropdown3">
          <MDBDropdownToggle
              tag="a"
              class="nav-link"
              @click="dropdown3 = !dropdown3"
          >
            <MDBIcon icon="user"/>
          </MDBDropdownToggle>
          <MDBDropdownMenu>
            <MDBDropdownItem to="/login">Anmelden</MDBDropdownItem>
            <MDBDropdownItem to="/account" :disabled="!user_store.getState().authenticated">Mein Account
            </MDBDropdownItem>
            <MDBDropdownItem v-if="isPhysician" to="/mydienzt" :disabled="!user_store.getState().authenticated">Mein
              DienZt
            </MDBDropdownItem>
            <MDBDropdownItem to="/forgotpassword">Passwort vergessen</MDBDropdownItem>
            <MDBDropdownItem to="/changepassword" :disabled="!user_store.getState().authenticated">Passwort ändern
            </MDBDropdownItem>
            <MDBDropdownItem to="/logout" :disabled="!user_store.getState().authenticated">Abmelden</MDBDropdownItem>
          </MDBDropdownMenu>
        </MDBDropdown>

      </MDBNavbarItem>


      <MDBNavbarItem class="me-3 me-lg-0" to="/logout" v-if="user_store.getState().authenticated">
        <MDBRow>{{ user_name }}</MDBRow>

        <MDBRow>
          <MDBNavbarItem>
            <Vidle class="small" @idle="router.push('/logout')" :duration="300"></Vidle>
          </MDBNavbarItem>
        </MDBRow>
      </MDBNavbarItem>
      <MDBNavbarItem class="me-3 me-lg-0" to="/login" v-if="!user_store.getState().authenticated">
        <img
            src="/disconnected.png"
            class="rounded-circle"
            height="24"
            alt=""
            loading="lazy"
        />
      </MDBNavbarItem>
    </MDBNavbarNav>


  </MDBNavbar>
</template>


<style scoped lang="scss">

</style>