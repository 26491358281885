import {Store} from "./store";
import axios from 'axios'
import {version} from "../package.json"

const date_format = 'DD.MM.YYYY'
const djangoDateFormat = 'YYYY-MM-DD'
const isoDateFormat = 'DD/MM/YYYY'
const wbauth_token_label = 'dpl_auth_token'
const backendURL = "https://static.94.87.140.128.clients.your-server.de/"

// local
//const backendURL = "http://localhost:8000/"

const client_id = "Ab8qozapRI5ICtOebnD4nNCCUeqJklUdEiBhah1B"
const client_secret = "6AftkHv9WVOA4TQWWB2JARafYVhJvsDPCgHo2NnKcneYzmhBcgSzxM9oEczmProwDe3SnSITIfVZqEIKqNVI1bToGddRFJhvbkL5qYV0SSfUEJhTHEdErzcQw8YJu2Rw"

const url_nutzungsbedingungen = "https://renecol.org/das-dienstplanungstool-dienzt/"
const url_datenschutz = "https://renecol.org/dienzt-datenschutzerklaerung-nach-dsgvo/"
const arbeitsvertrags_suffix = "#arbeitsvertrag"

const ADMINISTRATION_GROUPS = ['Verwaltung', 'Administration', 'Admin', 'Planer', 'Schedulizer', 'Planner']
const READER_STAFF_GROUPS = ['Reader']
const BASIC_USER_GROUP = ['Basic']

const wishCalculationDefaults = {no: 0, limited: 1, maybe: 2, yes: 3, mandatory: 4}
import _dg from "./assets/dienstgroups.json"
import {colors, names, NumberDictionary, uniqueNamesGenerator} from "unique-names-generator";
import {useRouter} from "vue-router";

export type DienstplanDataType = Array<{ group: string, Dienstplan: Array<Record<string, string>> }>

interface RemoteDienstplanDatasetDataRecord {
    Feiertage: Array<string>
    Dienstplan: Array<Record<string, string>>
    Dienstplaene?: DienstplanDataType
}

export interface RemoteDienstplanDatasetChangelogRecord {
    timestamp: string
    action: string
    date: string
    manipulator: string
    dg: string
    physician: string
}

export interface RemoteDienstplanDataset {
    id: string
    created: string
    last_modified: string
    not_the_newest?: boolean
    creator?: string
    creator_lastname?: string
    cocreators?: Array<string>
    cocreator_lastnames?: Array<string>
    referringDate: string
    status: string
    data: RemoteDienstplanDatasetDataRecord
    changelog: Array<RemoteDienstplanDatasetChangelogRecord>
}

export interface RemoteInstitutionDataDataset extends Object {
    Anwesenheitstunden: Record<string, Record<string, Number>>,
    Fza_Erfordernis: Record<string, boolean | undefined>,
    Ignore_For_Diff_Calc: Record<string, boolean | undefined>,
    Dienstmodell: Record<string, any>
    Zuschlaege: Record<string, Array<any>>
    Conversions: Record<string, Array<any>>
    dg_definitions: Record<string, Record<string, string>>
    wishCalculationParams?: Record<string, number>
    distributionInequality?: number
    dienst_social_score_statistics: Record<string,Record<('mean' | 'stddev'),number>>
    automation: Record<string, number>
}

export interface RemoteInstitutionDataset extends Object {
    id: string,
    created: string,
    expires: string,
    name: string,
    state_isocode: string,
    logo_url: string,
    codename: string,
    data: RemoteInstitutionDataDataset
}

interface AdditionalUserFlags {
    check_nutzungsbedingungen: boolean,
    check_datenschutz: boolean,
    check_datenschutz_av: boolean
}

export interface InstitutionSocialScoreAPI {
    user_scores: Record<string, number>,
    mean_score: number,
    stddev: number
}
export interface RemoteUserAPI {
    username: string,
    first_name: string,
    last_name: string,
    email: string,
    is_emailvalidated: boolean,
    pi_status: string,
    pi_serialnumber: string,
    additional_flags: AdditionalUserFlags,
    groups: Array<Record<'name', string>>
    physician_groups_of_my_institution: Array<string>
    institution_link: RemoteInstitutionDataset
    data_encrypted?: Record<string, any>
    data_dienzt_preferences?: Record<string, any>
}

interface User extends Object {
    oauth_credentials: Record<string, string>,
    access_token: string,
    authenticated: boolean,
    username: string,
    first_name: string,
    last_name: string,
    email: string,
    is_emailvalidated: boolean,
    pi_status: string,
    pi_serial: string,
    additional_flags: AdditionalUserFlags,
    groups: Array<string>,
    physician_groups_of_my_institution: Array<string>,
    institution: RemoteInstitutionDataset,
    data_encrypted: Record<string, any>,
    data_dienzt_preferences: Record<string, any>
}

export interface MinimalUserRecord extends Object {
    username: string,
    first_name: string,
    last_name: string,
    email: string,
    groups: Array<Record<'name', string>>
    is_emailvalidated: boolean,
    data_dienzt_preferences: Record<string, any>
    pi_status: string,
}

export const MonatsNamenLang = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']
export const MonatsNamenKurz = ['Jan', 'Feb', 'März', 'Apr', 'Mai', 'Juni', 'Juli', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez']

export const WochentagNamen = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']

class UserStore extends Store<User> {
    protected data(): User {
        return {
            oauth_credentials: Object.assign({}, {
                client_id: client_id,
                client_secret: client_secret
            }),
            access_token: '',
            authenticated: false,
            username: '',
            first_name: '',
            last_name: '',
            email: '',
            is_emailvalidated: false,
            pi_status: 'NO',
            pi_serial: '',
            additional_flags: {check_datenschutz: false, check_nutzungsbedingungen: false, check_datenschutz_av: false},
            groups: [],
            physician_groups_of_my_institution: [],
            institution: Object.assign({}, {
                id: '',
                created: '',
                expires: '',
                name: '',
                state_isocode: '',
                logo_url: '',
                codename: '',
                data: {
                    Anwesenheitstunden: {},
                    Fza_Erfordernis: {},
                    Ignore_For_Diff_Calc: {},
                    Dienstmodell: {},
                    Zuschlaege: {},
                    Conversions: {},
                    dg_definitions: Object.assign({}, _dg),
                    dienst_social_score_statistics: {},
                    automation: {},
                }
            }),
            data_encrypted: Object.assign({}, {}),
            data_dienzt_preferences: Object.assign({}, {})
        };
    }


    clear_userdata() {
        this.state.access_token = ''
        this.state.authenticated = false
        this.state.username = ''
        this.state.first_name = ''
        this.state.last_name = ''
        this.state.email = ''
        this.state.is_emailvalidated = false
        this.state.pi_status = 'NO'
        this.state.pi_serial = ''
        this.state.additional_flags = {
            check_datenschutz: false,
            check_nutzungsbedingungen: false,
            check_datenschutz_av: false
        },
            this.state.groups = []
        this.state.physician_groups_of_my_institution = []
        this.state.institution = Object.assign({}, {
            id: '',
            created: '',
            expires: '',
            name: '',
            state_isocode: '',
            logo_url: '',
            codename: '',
            data: {
                Anwesenheitstunden: {},
                Fza_Erfordernis: {},
                Ignore_For_Diff_Calc: {},
                Dienstmodell: {},
                Zuschlaege: {},
                Conversions: {},
                dg_definitions: Object.assign({}, _dg),
                dienst_social_score_statistics: {},
                automation: {}
            }
        })
        this.state.data_encrypted = Object.assign({}, {})
        this.state.data_dienzt_preferences = Object.assign({}, {})
    }

    get_token_from_browser() {
        return new Promise<string>((resolve, reject) => {
            let s = window.localStorage.getItem(wbauth_token_label)
            if (s) resolve(s)
            else reject('no Token')
        })
    }

    set_user(r: RemoteUserAPI) {
        this.state.username = r.username
        this.state.email = r.email
        this.state.is_emailvalidated = r.is_emailvalidated
        this.state.pi_status = r.pi_status
        this.state.pi_serial = r.pi_serialnumber
        this.state.first_name = r.first_name
        this.state.last_name = r.last_name
        if (r.additional_flags) this.state.additional_flags = Object.assign({}, r.additional_flags)
        this.state.groups = r.groups.map((x) => x.name)
        this.state.physician_groups_of_my_institution = r.physician_groups_of_my_institution
        this.state.institution = Object.assign({}, r.institution_link ? r.institution_link : this.state.institution)
        this.state.data_encrypted = Object.assign({}, r.data_encrypted)
        this.state.data_dienzt_preferences = Object.assign({}, r.data_dienzt_preferences)
    }

    set_email(email: string) {
        this.state.email = email
    }

    set_institution(i: RemoteInstitutionDataset) {
        this.state.institution = Object.assign({}, i)
    }

    set_institution_name(i: string) {
        this.state.institution.name = i
    }

    set_institution_state_isocode(c: string) {
        this.state.institution.state_isocode=c
    }

    set_institution_logo_url(i: string) {
        this.state.institution.logo_url = i
    }

    set_institution_codename(i: string) {
        this.state.institution.codename = i
    }

    set_check_nutzungsbedingungen(val: boolean) {
        this.state.additional_flags.check_nutzungsbedingungen = val
    }

    set_check_datenschutz(val: boolean) {
        this.state.additional_flags.check_datenschutz = val
    }

    set_check_datenschutz_av(val: boolean) {
        this.updateAdditionalFlags({...this.state.additional_flags, check_datenschutz_av: val})
            .then(() => this.state.additional_flags.check_datenschutz_av = val)
    }

    set_pi_status(val: string) {
        this.state.pi_status = val
    }

    set_pi_serial(val: string) {
        this.state.pi_serial = val
    }

    createRandomCodename(): Promise<string> {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                let cname = ''
                let numberDictionary = NumberDictionary.generate({min: 10, max: 99});
                cname = uniqueNamesGenerator({
                    dictionaries: [names, numberDictionary],
                    length: 2,
                    separator: '',
                    style: 'capital'
                });
                this.checkCodename(cname).then(() => {
                    resolve(cname)
                }).catch(() => this.createRandomCodename())  // repeat if checkCodename fails
            }, 1000)
        })
    }


    createRandomInstitution() {
        const shortName: string = uniqueNamesGenerator({
            dictionaries: [['The'], colors, names, ['Institution', 'Hospital', 'Company', 'Department']],
            length: 4,
            separator: ' ',
            style: 'capital'
        });
        return shortName
    }

    initializeInstitutionWithRandomData() {
        return new Promise((resolve, reject) => {
            let iname = this.createRandomInstitution()
            // there should be some routine for random logo image picking
            this.modifyInstitutionAtApi('POST', '', {  // try to make this institution
                name: iname
            }).then((res) => {
                this.set_institution(res)
                this.createRandomCodename().then(cname => {
                    // there should be some routine for random logo image picking
                    this.modifyInstitutionAtApi('PATCH', this.state.institution?.id + '/', {
                        name: iname,
                        logo_url: '',
                        codename: cname
                    })
                        .then((r) => {
                            this.set_institution(r)
                            resolve(iname)
                        })
                        .catch((e) => {
                            console.log('error while creating institution: ', e)
                            reject(e)
                        })
                })
            })
        })
    }


    register(email: string, firstname: string, lastname: string, password: string, codename: string, newclinic: boolean): Promise<string> {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                var data = new FormData();
                data.append('client_id', this.state.oauth_credentials.client_id);
                data.append('client_secret', this.state.oauth_credentials.client_secret);
                data.append('grant_type', 'password');
                data.append('email', email)
                data.append('first_name', firstname);
                data.append('last_name', lastname)
                data.append('password', password);
                data.append('codename', codename);
                if (newclinic) data.append('notify', 'new clinic')
                var config = {
                    method: 'POST',
                    url: backendURL + "register/",
                    data: data
                };
                //obtain token
                axios(config)
                    .then((response) => {
                        resolve(response.data.access_token)
                    }).catch((error) => {
                    console.log("...failed:", error);
                    reject()
                })
            }, 1000)
        })
    }

    connect_user_to_api() {
        return new Promise<string>((resolve, reject) => {
            this.get_token_from_browser()
                .then((token) => {
                    console.log('Trying to log in with browser token ... ')
                    this.checkToken(token)
                        .then((r) => {
                            this.state.authenticated = true
                            this.set_user(r)
                            console.log('... succeeded')
                            resolve('connected')
                        }).catch((e) => {
                        console.log('... failed, because ', e)
                        reject(e)
                    })
                })
                .catch((e) => {
                    this.state.authenticated = false
                    console.log(e)
                    reject('login required')
                })
        })
    }

    set_access_token(access_token: string) {
        this.state.access_token = access_token
        this.state.authenticated = true
        window.localStorage.setItem(wbauth_token_label, access_token)
    }

    updateSensitiveData(sdata: Record<string, any>): Promise<RemoteUserAPI> {
        return new Promise((resolve, reject) => {
            if (this.state.authenticated) {
                var config = {
                    method: 'PATCH',
                    url: backendURL + `users/${this.state.username}/`,
                    headers: {
                        authorization: `Bearer ${this.state.access_token}`,
                        'Content-Type': 'application/json'
                    },
                    xhrFields: {
                        withCredentials: true
                    },
                    data: {data_encrypted: sdata},
                }
                axios(config).then((response) => {
                    this.set_user(response.data)
                    resolve(response.data)
                }).catch((e) => {
                    console.log('Update of sensitive User data failed: ', e)
                    reject(e);
                })
            } else reject('Not authenticated')
        })
    }

    updateAdditionalFlags(addflags: AdditionalUserFlags): Promise<RemoteUserAPI> {
        return new Promise((resolve, reject) => {
            if (this.state.authenticated) {
                var config = {
                    method: 'PATCH',
                    url: backendURL + `users/${this.state.username}/`,
                    headers: {
                        authorization: `Bearer ${this.state.access_token}`,
                        'Content-Type': 'application/json'
                    },
                    xhrFields: {
                        withCredentials: true
                    },
                    data: {additional_flags: addflags},
                }
                axios(config).then((response) => {
                    this.set_user(response.data)
                    resolve(response.data)
                }).catch((e) => {
                    console.log('Update of additional flags failed: ', e)
                    reject(e);
                })
            } else reject('Not authenticated')
        })
    }

    updateDienztPrefs(data: Record<string, any>, username?: string): Promise<RemoteUserAPI> {
        return new Promise((resolve, reject) => {
            if (this.state.authenticated) {
                var config = {
                    method: 'PATCH',
                    url: backendURL + `users/${username || this.state.username}/`,
                    headers: {
                        authorization: `Bearer ${this.state.access_token}`,
                        'Content-Type': 'application/json'
                    },
                    xhrFields: {
                        withCredentials: true
                    },
                    data: {data_dienzt_preferences: data},
                }
                axios(config).then((response) => {
                    if (!(username)) this.set_user(response.data)
                    resolve(response.data)
                }).catch((e) => {
                    console.log('Update of Dienzt Preferences failed: ', e)
                    reject(e);
                })
            } else reject('Not authenticated')
        })
    }

    checkToken(token: string, mfa_token?: string): Promise<RemoteUserAPI> {
        return new Promise((resolve, reject) => {
            // get and set auth user
            //console.log('Checking Token: ', rootState.token)
            this.state.access_token = token.length > 0 ? token : this.state.access_token
            if (this.state.access_token.length > 0) {
                const config = {
                    url: backendURL + "userinfo/",
                    method: 'GET',
                    headers: {
                        authorization: `Bearer ${this.state.access_token}`,
                        'Content-Type': 'application/json'
                    },
                    xhrFields: {
                        withCredentials: true
                    },
                    params: {
                        mfa_token: mfa_token
                    }
                }
                axios(config).then((response) => {
                    resolve(response.data)
                }).catch((e) => {
                    console.log(e)
                    reject(e)
                })
            } else {
                reject('Missing Token')
            }
        })
    }

    checkCodename(codename: string) {
        return new Promise<void>((resolve, reject) => {
            if (this.state.authenticated) {
                var config = {
                    method: 'GET',
                    url: backendURL + `institution/${this.state.institution.id}/checkcodename/`,
                    headers: {
                        authorization: `Bearer ${this.state.access_token}`,
                        'Content-Type': 'application/json'
                    },
                    xhrFields: {
                        withCredentials: true
                    },
                    params: {
                        codename: codename
                    }
                };
                axios(config).then(() => {
                    resolve()
                }).catch(() => {
                    reject();
                })
            } else reject('Not authenticated')
        })
    }

    loadDienstplaeneFromApi(all?: string): Promise<Array<RemoteDienstplanDataset>> {
        return new Promise((resolve, reject) => {
            if (this.state.authenticated) {
                var config = {
                    method: 'GET',
                    url: backendURL + "datastore/dienstplan/",
                    headers: {
                        authorization: `Bearer ${this.state.access_token}`,
                        'Content-Type': 'application/json'
                    },
                    xhrFields: {
                        withCredentials: true
                    },
                    params: {
                        all: all
                    }
                };
                axios(config).then((response) => {
                    resolve(response.data)
                }).catch((e) => {
                    console.log('Send of Dienstplan failed: ', e)
                    reject(e);
                })
            } else reject('Not authenticated')
        })
    }

    getMitarbeiterListOfMyInstitution(subset: string | undefined): Promise<Array<MinimalUserRecord>> {
        return new Promise((resolve, reject) => {
            if (this.state.authenticated) {
                var config = {
                    method: 'GET',
                    url: backendURL + `users/getmitarbeiterofmyinstitution/`,
                    headers: {
                        authorization: `Bearer ${this.state.access_token}`,
                        'Content-Type': 'application/json'
                    },
                    xhrFields: {
                        withCredentials: true
                    },
                    params: {
                        subset: subset
                    }
                };
                axios(config).then((response) => {
                    resolve(response.data)
                }).catch((e) => {
                    reject(e);
                })
            } else reject('Not authenticated')
        })
    }

    getSocialScores(yearmonth: string) {
        return new Promise<InstitutionSocialScoreAPI>((resolve, reject) => {
            if (this.state.authenticated) {
                var config = {
                    method: 'GET',
                    url: backendURL + `institution/${this.state.institution.id}/getsocialscores/`,
                    headers: {
                        authorization: `Bearer ${this.state.access_token}`,
                        'Content-Type': 'application/json'
                    },
                    xhrFields: {
                        withCredentials: true
                    },
                    params: {
                        yearmonth: yearmonth
                    }
                };
                axios(config).then((r) => {
                    resolve(r.data)
                }).catch((e) => {
                    reject(e);
                })
            } else reject('Not authenticated')
        })
    }

    email_to_user(username: string, email_type: 'reminder' | 'rewish', folder: 'dienstplan' | 'user', add_info?:any) {
        return new Promise((resolve, reject) => {
            if (this.state.authenticated) {
                var config = {
                    method: 'GET',
                    url: backendURL + `users/${username}/email_user/`,
                    headers: {
                        authorization: `Bearer ${this.state.access_token}`,
                        'Content-Type': 'application/json'
                    },
                    xhrFields: {
                        withCredentials: true
                    },
                    params: {
                        type: email_type,
                        folder: folder,
                        add_info: add_info
                    }
                };
                axios(config).then((response) => {
                    resolve(response.data)
                }).catch((e) => {
                    reject(e);
                })
            } else reject('Not authenticated')
        })
    }

    email_to_institution(email_type: 'generate' | 'validate', folder: 'dienstplan' | 'user', to_group: 'physicians' | undefined, add_info: string | undefined) {
        return new Promise((resolve, reject) => {
            if (this.state.authenticated) {
                var config = {
                    method: 'GET',
                    url: backendURL + `users/${this.state.username}/email_institution/`,
                    headers: {
                        authorization: `Bearer ${this.state.access_token}`,
                        'Content-Type': 'application/json'
                    },
                    xhrFields: {
                        withCredentials: true
                    },
                    params: {
                        type: email_type,
                        folder: folder,
                        add_info: add_info
                    }
                };
                axios(config).then((response) => {
                    resolve(response.data)
                }).catch((e) => {
                    reject(e);
                })
            } else reject('Not authenticated')
        })
    }

    trigger_token(): Promise<string> {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                var config = {
                    method: 'GET',
                    url: backendURL + `users/${this.state.username}/email_user_token/`,
                    headers: {
                        authorization: `Bearer ${this.state.access_token}`,
                        'Content-Type': 'application/json'
                    },
                    xhrFields: {
                        withCredentials: true
                    },
                };
                //obtain token
                axios(config)
                    .then((response) => {
                        resolve(response.data.username)
                    }).catch((error) => {
                    console.log("...failed:", error);
                    reject()
                })
            }, 1000)
        })
    }

    modifyInstitutionAtApi(method: 'POST' | 'PATCH', pk: string, data: Object): Promise<RemoteInstitutionDataset> {
        return new Promise((resolve, reject) => {
            if ((this.state.authenticated) && (Object.keys(data).length > 0)) {
                var config = {
                    method: method,
                    url: backendURL + "datastore/institution/" + pk,
                    headers: {
                        authorization: `Bearer ${this.state.access_token}`,
                        'Content-Type': 'application/json'
                    },
                    xhrFields: {
                        withCredentials: true
                    },
                    data: data
                };
                axios(config).then((response) => {
                    resolve(response.data)
                }).catch((e) => {
                    console.log('Create of institution failed: ', e)
                    reject(e);
                })
            } else reject('Not authenticated or no name provided')
        })
    }

    logout(): Promise<void> {
        return new Promise((resolve) => {
            setTimeout(() => {
                //vm.$auth.logout();
                //revoking AccessToken
                var data = new FormData();
                data.append('client_id', this.state.oauth_credentials.client_id);
                data.append('client_secret', this.state.oauth_credentials.client_secret);
                data.append('token', this.state.access_token)
                var config = {
                    method: 'POST',
                    headers: {
                        authorization: `Bearer ${this.state.access_token}`,
                        'Content-Type': 'multipart/form-data'
                    },
                    xhrFields: {
                        withCredentials: true
                    },
                    url: backendURL + 'o/revoke_token/',
                    data: data
                };
                axios(config).finally(() => {
                    resolve()
                })

            }, 1000)
        })
    }

    goLogout() {
        if (this.state.access_token) {
            this.logout().then(() => {
                window.localStorage.removeItem(wbauth_token_label)
                this.clear_userdata()
            })
        }
    }

}

const user_store = new UserStore()
export {
    user_store,
    date_format,
    isoDateFormat,
    djangoDateFormat,
    wbauth_token_label,
    backendURL,
    version,
    ADMINISTRATION_GROUPS,
    wishCalculationDefaults,
    url_nutzungsbedingungen,
    url_datenschutz,
    arbeitsvertrags_suffix,

}
